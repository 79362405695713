/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import Page404 from "@/images/lp/home/404.svg"

import { 
  contentPage,
}  from '../assets/styles/Other.styles'

const NotFoundContent = () => {
  return (
    <Fragment>
      <section css={contentPage}>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <h2>Ooops, Algo deu Errado!</h2>
              <p>Fique tranquilo, nossos técnicos estão trabalhando nisso.</p>
            </Col>
            <Col lg={5} md={5} sm={12}>
              <img src={Page404} alt='/' />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default NotFoundContent;
