/** @jsx jsx */
import { css } from "@emotion/react";

export const contentPage = css`
  margin-top: 86px;
  position: relative;
  padding: 100px 0;
  background: #EAECED;
  font-family:"owners-narrow";
  font-variation-settings: "wght" 700;
  h2 {
    color: #484848;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-bottom: 16px;
    margin: auto;
    text-align: center;
    width: 100%;
    max-width: 920px;
    margin-top: 16px;
    span {
      color: #776AAE;
    }
  }
  a {
    color: #484848;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-bottom: 16px;
    span {
      color: #776AAE;
    }
    :hover {
      color: #776AAE;
    }
  }
  p {
    color: #484848;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 30.8px */
    text-transform: uppercase;
    margin-bottom: 0px;
  }
  button{
    width:100%;
    color: #484848;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 30.8px */
    text-transform: uppercase;
    background-color: transparent;
    margin-bottom: 0px;
    border: none;
    text-align: center;
    transition: .3s;
    span {
      color: #776AAE;
    }
    :hover {
      color: #776AAE;
      text-decoration: underline;
    }
  }
`

export const politicaPage = css`
  margin-top: 42px;
  position: relative;
  padding: 120px 0;
  background: #C2F57E;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  h2 {
    color: #33535E;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  h3 {
    color: #33535E;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-top: 40px;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
    font-style: normal;
    line-height: 120%;
    margin-bottom: 16px;
    color: #33535E;
  }
`

export const demandasContent = css`
  position: relative;
  padding: 60px 0;
  background: #EAECED;
  font-family:"owners-narrow";
  font-variation-settings: "wght" 700;
  h2 {
    color: #484848;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-bottom: 16px;
    margin: auto;
    text-align: center;
    width: 100%;
    max-width: 920px;
    margin-top: 16px;
    span {
      color: #776AAE;
    }
  }
  a {
    color: #484848;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-bottom: 16px;
    span {
      color: #776AAE;
    }
    :hover {
      color: #776AAE;
    }
  }
  p {
    color: #484848;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 30.8px */
    text-transform: uppercase;
    margin-bottom: 0px;
  }
`

export const boxContent = css`
  background: #FFFFFF;
  border-radius: 16px;
  padding: 60px 0px;
  margin-bottom: 56px;
  font-family:"owners-narrow";
  font-variation-settings: "wght" 700;
  h3 {
    color: #776AAE;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 96px */
    text-transform: uppercase;
    margin-bottom: 16px;
    margin: auto;
    text-align: center;
    width: 100%;
    max-width: 920px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  p {
    color: #484848;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 30.8px */
    text-transform: uppercase;
    margin-bottom: 56px;
  }
`

export const boxService = css`
  background: #A16FFF;
  border-radius: 16px;
  padding: 57px 52px;
  text-align: center;
  font-family:"owners-narrow";
  font-variation-settings: "wght" 700;
  margin-bottom: 32px;
  h3 {
    color: #EAEBE4;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 32px;
    margin-top: 8px;
    text-transform: uppercase;
  }
  a {
    border-radius: 12px;
    background: #311B68;
    color: #ffffff !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 18px */
    text-transform: uppercase;
    padding: 14px 32px;
    :hover {
      background: #776AAE;
      color: #EAECED;
      text-decoration: none;
    }
    :active {
      background: #443B6D;
      color: #EAECED;
    }
  }
`